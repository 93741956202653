import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './card.css';
import CtaButton from 'components/ctabutton';
import Title from 'components/title';

const Card = ({ children, title, linkTo }) => (
  <Container className={'card'}>
    <Title as="h3" size="extrasmall" color="white">
      {title}
    </Title>

    <CtaButton linkTo={linkTo} style="basic">
      Scopri
    </CtaButton>

    {children}
  </Container>
);

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  linkTo: PropTypes.string.isRequired,
};

export default Card;
