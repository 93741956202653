import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import CtaButton from 'components/ctabutton';
import Head from 'components/head';
import SectionMain from 'components/section-main';
import Title from 'components/title';
import Card from 'components/card';
import ProfileImage from 'components/profile-image';
import {
  Aside,
  AsideProfileImage,
  AsideHomeMain,
} from 'components/section-main/aside.css';
import Graph from 'images/pattern-circual.svg';
import Graph1 from 'images/pattern-triangle3-oblique.svg';
import COLORS from 'constants/colors';
import { gridTemplateColumns } from 'constants/grid';
import {
  PreviewWrapper,
  PreviewButton,
} from 'components/photogallery/photogallery.css';
import PatternTripleTrianglePink from 'images/pattern-triple-triangle-pink.svg';
import PatternSquareBlu from 'images/pattern-square-blu.svg';

const Index = ({ data }) => {
  const MAIN = data.homeJson.sections[0];
  const CORSI = data.homeJson.sections[1];
  const TEAM = data.homeJson.sections[2];
  const GALLERY = data.homeJson.sections[3];
  const items = data.allFile.edges;

  return (
    <>
      <Head imageUrl={data.homeJson.socialImage.childImageSharp.fixed.src} />

      <SectionMain>
        <Title as="h2" color="green" size="extralarge">
          {MAIN.title}
        </Title>

        <Title as="h3" className="offsetLeft" color="darkGreen">
          {MAIN.subTitle}
        </Title>

        <Title as="h3" size="lowercase" className="offsetLeft" color="blue">
          {MAIN.cta}
        </Title>

        <AsideHomeMain>
          <Graph fill={COLORS.lightBlue} />
          <Graph1 />
          <Img fluid={MAIN.images[0].childImageSharp.fluid} />
          <Img fluid={MAIN.images[1].childImageSharp.fluid} />
          <Img fluid={MAIN.images[2].childImageSharp.fluid} />
        </AsideHomeMain>
      </SectionMain>

      <SectionMain theme={{ gridRows: 'auto auto 1fr' }}>
        <Title as="h2" color="blue" className="offsetRight">
          {CORSI.title}
        </Title>

        <div
          className="offsetRight"
          dangerouslySetInnerHTML={{
            __html: CORSI.content.childMarkdownRemark.html,
          }}
        />

        <CtaButton linkTo="/corsi" fontWeight="normal" className="offsetRight">
          {CORSI.cta}
        </CtaButton>

        <Aside>
          <Card title={CORSI.aside[0].title} linkTo="/corsi">
            <Img fluid={CORSI.aside[0].image.childImageSharp.fluid} />
          </Card>

          <Card title={CORSI.aside[1].title} linkTo="/corsi">
            <Img fluid={CORSI.aside[1].image.childImageSharp.fluid} />
          </Card>
        </Aside>
      </SectionMain>

      <SectionMain theme={{ gridRows: 'auto auto 1fr', asideAlign: 'left' }}>
        <Title as="h2" color="blue">
          {TEAM.title}
        </Title>

        <div
          className="offsetRight"
          dangerouslySetInnerHTML={{
            __html: TEAM.content.childMarkdownRemark.html,
          }}
        />

        <CtaButton linkTo="/team" fontWeight="normal">
          {TEAM.cta}
        </CtaButton>

        <AsideProfileImage theme={{ align: 'left' }}>
          <ProfileImage color="green" isGraph={true}>
            <Img fluid={TEAM.aside[0].image.childImageSharp.fluid} />
          </ProfileImage>
          <Title color="blue" size="extrasmall">
            {TEAM.aside[0].title}
          </Title>

          <ProfileImage color="darkGreen" isGraph={true}>
            <Img fluid={TEAM.aside[1].image.childImageSharp.fluid} />
          </ProfileImage>
          <Title color="blue" size="extrasmall">
            {TEAM.aside[1].title}
          </Title>
        </AsideProfileImage>
      </SectionMain>
      <SectionMain
        bgColor="blue"
        bgPattern={true}
        theme={{ gridColumns: gridTemplateColumns.col48, gridRows: '1fr auto' }}
      >
        <Title as="h2" color="pink" bordered={true}>
          {GALLERY.title}
        </Title>

        <PreviewWrapper>
          {items.map((node, i) => (
            <Img
              fluid={node.node.childImageSharp.fluid}
              sizes={node.sizes}
              key={i}
            />
          ))}
        </PreviewWrapper>

        <PreviewButton>
          <CtaButton
            linkTo="/gallery"
            fontWeight="bold"
            style="rounded-secondary"
            icon="photo"
          >
            {GALLERY.cta}
          </CtaButton>
        </PreviewButton>

        <PatternSquareBlu />
        <PatternTripleTrianglePink />
      </SectionMain>
    </>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query {
    homeJson {
      title
      socialImage {
        childImageSharp {
          fixed(width: 1025, height: 512, cropFocus: CENTER) {
            src
          }
        }
      }
      sections {
        title
        subTitle
        cta
        images {
          childImageSharp {
            fluid(maxWidth: 640) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        content {
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
        aside {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 640) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    allFile(
      filter: {
        absolutePath: { regex: "/content/gallery/" }
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
      }
      limit: 3
      sort: { fields: modifiedTime, order: ASC }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 320) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
