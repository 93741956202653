import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;

  h3 {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1;
  }

  .gatsby-image-wrapper {
    z-index: -1;
    width: 100%;
    height: 100%;
  }
`;
